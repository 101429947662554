import React from 'react';
import NewsletterTemplate from 'templates/newsletter';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Content = () => {
  const { customEvents } = useStaticQuery(graphql`
    query {
      customEvents: file(relativePath: { eq: "blog/custom-events/custom-events1.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <table className="content" cellSpacing="0" cellPadding="0" align="center" width="100%">
      <tbody>
        <tr>
          <td>
            <table className="text" cellSpacing="0" cellPadding="0" style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td style={{ padding: '40px 40px 12px' }}>
                    <h1>
                      Hi{' '}
                      <span role="img" aria-label="emoji">
                        &#128075;
                      </span>
                    </h1>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '12px 40px' }}>
                    <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>
                      Right before Christmas🎅 we have few cool news, let&apos;s check it out!
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '12px 0 34px' }}>
                    <div style={{ borderTop: '1px solid #ddd' }} />
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '0 40px' }}>
                    <h2 style={{ margin: 0, fontSize: '22px' }}>Product updates</h2>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '32px 40px 12px' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <a
                              href="/blog/custom-events/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ display: 'block' }}
                            >
                              <Img
                                fluid={customEvents.childImageSharp.fluid}
                                alt="Product update - Custom Events"
                                style={{
                                  border: 0,
                                  lineHeight: '100%',
                                  outline: 0,
                                  display: 'block',
                                  height: 'auto',
                                  borderRadius: '6px',
                                  maxWidth: '100%',
                                }}
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a
                              href="/blog/custom-events/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: 'none',
                                color: '#061631',
                                fontWeight: 800,
                                marginTop: '24px',
                                display: 'block',
                              }}
                            >
                              Custom Events
                            </a>
                            <p
                              style={{
                                color: '#9b9b9b',
                                fontSize: 16,
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              This feature allows you to track custom actions that your users
                              perform along with <strong>custom event</strong> properties.
                            </p>
                            <p
                              style={{
                                color: '#9b9b9b',
                                fontSize: 16,
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              To find Custom Events, open filters and then go to replays you are
                              interested in.
                            </p>
                            <a
                              href="/blog/custom-events/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                fontSize: '16px',
                                color: '#0446CA',
                                fontWeight: 700,
                                textDecoration: 'none',
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              Try the new Custom Events
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '28px 40px 8px' }}>
                    <p className="wishes" style={{ margin: 0 }}>
                      Merry Xmas & Happy New Year Wishes from,
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '8px 40px 32px' }}>
                    <p className="team" style={{ margin: 0 }}>
                      <strong>LiveSession Team</strong> 🎄
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const Newsletter = NewsletterTemplate(Content);

export const frontmatter = {
  title: 'LiveSession Newsletter December 2020',
  description: "Read LiveSession's monthly update from December 2020.",
  date: '2020-12-01',
  link: '/newsletters/december-2020/',
};

export default () => <Newsletter {...frontmatter} />;
