import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 16px;
    max-width: 34px;
  }
`;

export const CardTitle = styled.h2`
  font-size: 24px;
  line-height: 36px;
  position: absolute;
  top: 50%;
  left: 32px;
  max-width: calc(100% - 64px);
`;

export const Card = styled.a`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 32px;
  background-color: #f8f8f8;
  border-radius: 4px;
  flex-direction: column;
  min-height: 273px;
  color: #000;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: #000;
    & ${CardTitle} {
      text-decoration: underline;
    }
  }
`;
