import React from 'react';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import Layout from 'layout';
import CardPreview from 'components/newsletters/cardPreview';
import NewsletterBox from 'components/blog/newsletterBox';
import { newsletterPosts } from 'data/newsletters';
import StartUsing from 'components/ui/startUsing';
import { Container, Card } from 'components/newsletters/cardPreview/styled';

const StyledSection = styled.section`
  margin-top: 6rem;
  & h1 {
    text-align: center;
  }
  & .subtitle {
    margin: 40px 0 80px;
    font-size: 22px;
    text-align: center;
  }
  ${media.mobile} {
    margin-top: 1rem;
    & .subtitle {
      font-size: 20px;
      margin: 32px 0 80px;
    }
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  margin-bottom: 50px;
  & ${Card}:nth-child(3n) ${Container} svg {
    & .a {
      fill: rgb(159, 252, 239);
    }
  }
  & ${Card}:nth-child(2n) ${Container} svg {
    & .a {
      fill: rgb(130, 102, 246);
    }
  }
  ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Newsletters = () => {
  return (
    <Layout
      metaTitle="Newsletter Archive"
      canonical="/newsletters/"
      metaDescription="Missed an update? Read LiveSessions newsletters from the previous months."
    >
      <StyledSection>
        <div className="container">
          <h1 className="line">
            Newsletter <span>archive</span>
          </h1>
          <p className="subtitle">Missed a newsletter? Read all previous updates here:</p>
          <GridContainer>
            {newsletterPosts.map((post) => (
              <CardPreview {...post} key={post.title} />
            ))}
          </GridContainer>
        </div>
        <NewsletterBox />
        <div className="container">
          <StartUsing />
        </div>
      </StyledSection>
    </Layout>
  );
};

export default Newsletters;
