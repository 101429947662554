import { frontmatter as post7 } from 'pages/newsletters/september-2020';
import { frontmatter as post6 } from 'pages/newsletters/july-2020';
import { frontmatter as post5 } from 'pages/newsletters/june-2020';
import { frontmatter as post4 } from 'pages/newsletters/may-2020';
import { frontmatter as post1 } from 'pages/newsletters/april-2020';
import { frontmatter as post2 } from 'pages/newsletters/march-2020';
import { frontmatter as post3 } from 'pages/newsletters/january-2020';
import { frontmatter as post8 } from 'pages/newsletters/december-2020';
import { frontmatter as post9 } from 'pages/newsletters/february-2021';
import { frontmatter as post10 } from 'pages/newsletters/march-2021';
import { frontmatter as post11 } from 'pages/newsletters/april-2021';
import { frontmatter as post12 } from 'pages/newsletters/may-2021';
import { frontmatter as post13 } from 'pages/newsletters/june-2021';

export const newsletterPosts = [
  post13,
  post12,
  post11,
  post10,
  post9,
  post8,
  post7,
  post6,
  post5,
  post4,
  post1,
  post2,
  post3,
];
