import React from 'react';
import NewsletterTemplate from 'templates/newsletter';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Content = () => {
  const { customEvents, userJourney, coverSegments, remoteUX, ecommerceUX } =
    useStaticQuery(graphql`
      query {
        customEvents: file(relativePath: { eq: "blog/custom-events/custom-events1.png" }) {
          childImageSharp {
            fluid(quality: 95) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        userJourney: file(relativePath: { eq: "blog/user-journey/img1.png" }) {
          childImageSharp {
            fluid(quality: 95) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        coverSegments: file(relativePath: { eq: "blog/covers/segment-notifications.png" }) {
          childImageSharp {
            fluid(quality: 95) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        remoteUX: file(relativePath: { eq: "blog/covers/remote-ux.png" }) {
          childImageSharp {
            fluid(quality: 95) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        ecommerceUX: file(relativePath: { eq: "blog/covers/ecommerce-ux.png" }) {
          childImageSharp {
            fluid(quality: 95) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `);

  return (
    <table className="content" cellSpacing="0" cellPadding="0" align="center" width="100%">
      <tbody>
        <tr>
          <td>
            <table className="text" cellSpacing="0" cellPadding="0" style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td style={{ padding: '40px 40px 24px' }}>
                    <p style={{ margin: 0 }}>
                      Hi{' '}
                      <span role="img" aria-label="emoji">
                        &#128075;
                      </span>
                      <br />
                      <br />
                      You are probably wondering what’s going on with us. Well, not gonna lie, we’ve
                      been swamped these days, but managed to deliver:
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '0 40px' }}>
                    <h2 style={{ margin: 0, fontSize: '22px', lineHeight: 'normal' }}>
                      Custom events
                    </h2>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '32px 40px 12px' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <a
                              href="/blog/custom-events/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ display: 'block' }}
                            >
                              <Img
                                fluid={customEvents.childImageSharp.fluid}
                                alt="Product update - Custom Events"
                                style={{
                                  border: 0,
                                  lineHeight: '100%',
                                  outline: 0,
                                  display: 'block',
                                  height: 'auto',
                                  borderRadius: '6px',
                                  maxWidth: '100%',
                                }}
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p
                              style={{
                                color: '#000',
                                fontSize: 16,
                                marginTop: 24,
                                display: 'block',
                              }}
                            >
                              <strong>It’s finally here!</strong> Last year we implemented Custom
                              Events, something many of you were waiting for. Now you can gather
                              even more meaningful data, fully tailored for your business needs.
                              Using Custom Events, you can enrich every session replay with
                              information about:
                            </p>
                            <p
                              style={{
                                color: '#000',
                                fontSize: 16,
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              your customers’ <strong>order value</strong>{' '}
                              <span role="img" aria-label="emoji">
                                💰
                              </span>{' '}
                              <br />
                              what product did the user <strong>add to the cart</strong>{' '}
                              <span role="img" aria-label="emoji">
                                🛒
                              </span>{' '}
                              <br />
                              which{' '}
                              <strong>product’s variant was the customer interested in</strong>{' '}
                              <span role="img" aria-label="emoji">
                                🔘
                              </span>{' '}
                              <br />
                            </p>
                            <a
                              href="/help/how-do-the-custom-events-work/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="with-arrow"
                              style={{
                                fontSize: '16px',
                                color: '#0446CA',
                                fontWeight: 700,
                                textDecoration: 'none',
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              Learn more about how our Custom Events work
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '34px 40px 0 ' }}>
                    <h2 style={{ margin: 0, fontSize: '22px', lineHeight: 'normal' }}>
                      User journey
                    </h2>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '0 40px' }}>
                    <p
                      style={{
                        color: '#000',
                        fontSize: 16,
                        marginTop: 24,
                        display: 'block',
                        marginBottom: 0,
                      }}
                    >
                      Want to know the user’s most essential actions during the session? Now it’s
                      even easier with our User journey feature! You will find it by hovering on the
                      “pages” link:
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '28px 40px 12px' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <a
                              href="/blog/user-journey/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ display: 'block' }}
                            >
                              <Img
                                fluid={userJourney.childImageSharp.fluid}
                                alt="Product update - User journey"
                                style={{
                                  border: 0,
                                  lineHeight: '100%',
                                  outline: 0,
                                  display: 'block',
                                  height: 'auto',
                                  borderRadius: '6px',
                                  maxWidth: '100%',
                                }}
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p
                              style={{
                                color: '#000',
                                fontSize: 16,
                                marginTop: 8,
                                display: 'block',
                                marginBottom: 0,
                              }}
                            >
                              You can get a sneak peek at the user’s activity straight from the
                              Sessions tab and fast forward to the event you are the most interested
                              in.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: '28px 40px' }}>
                            <a
                              href="https://app.livesession.io/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="go-to-app"
                              style={{
                                display: 'block',
                                margin: '0 auto',
                                backgroundColor: '#0a4ed6',
                                padding: '10px 0',
                                fontWeight: 700,
                                color: '#fff',
                                borderRadius: '4px',
                                width: '188px',
                                textAlign: 'center',
                              }}
                            >
                              Log in to LiveSession
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0 40px' }}>
                    <h2 style={{ margin: 0, fontSize: '22px', lineHeight: 'normal' }}>
                      Segments summary email
                    </h2>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '0 40px' }}>
                    <p
                      style={{
                        color: '#000',
                        fontSize: 16,
                        marginTop: 24,
                        display: 'block',
                        marginBottom: 0,
                      }}
                    >
                      You do like our daily summary emails, don’t you? Now you can get an update on
                      what has changed in any particular segment. Just hit the “Add to summary”
                      button while the segment is selected, and you’re good to go:
                    </p>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '32px 40px 12px' }}>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <td>
                            <a
                              href="/blog/segments-summary-email/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ display: 'block' }}
                            >
                              <Img
                                fluid={coverSegments.childImageSharp.fluid}
                                alt="Product update - Segments summary email"
                                style={{
                                  border: 0,
                                  lineHeight: '100%',
                                  outline: 0,
                                  display: 'block',
                                  height: 'auto',
                                  borderRadius: '6px',
                                  maxWidth: '100%',
                                }}
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: '24px 0 12px' }}>
                            <a
                              href="https://app.livesession.io/app/settings/notifications"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="with-arrow"
                              style={{
                                fontSize: '16px',
                                color: '#0446CA',
                                fontWeight: 700,
                                textDecoration: 'none',
                                marginTop: 12,
                                display: 'block',
                              }}
                            >
                              Set up segments summary
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '12px 0 34px' }}>
                    <div style={{ borderTop: '1px solid #ddd' }} />
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '12px 40px 12px' }}>
                    <h2 style={{ margin: 0, fontSize: 22, lineHeight: 'normal' }}>
                      <span>New on our blog</span>
                    </h2>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '32px 40px 12px' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <a
                              href="/blog/4-ways-to-improve-ecommerce-ux-for-website-visitors/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ display: 'block' }}
                            >
                              <Img
                                fluid={ecommerceUX.childImageSharp.fluid}
                                alt="Blog post - 4 Ways To Improve eCommerce UX For Website Visitors"
                                style={{
                                  border: 0,
                                  lineHeight: '100%',
                                  outline: 0,
                                  display: 'block',
                                  height: 'auto',
                                  borderRadius: '6px',
                                  maxWidth: '100%',
                                }}
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a
                              href="/blog/4-ways-to-improve-ecommerce-ux-for-website-visitors/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: 'none',
                                color: '#061631',
                                fontWeight: 800,
                                marginTop: '24px',
                                display: 'block',
                              }}
                            >
                              4 Ways To Improve eCommerce UX For Website Visitors
                            </a>
                            <p
                              style={{
                                color: '#9b9b9b',
                                fontSize: 16,
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              When it comes to customers shopping online, they want a similar feel
                              to a physical brick and mortar store. Whether they’re looking for a
                              particular item, or want to buy anything at all, only they have the
                              buying power. Therefore, their buying experience should be as
                              seamlessly as possible.
                            </p>

                            <a
                              href="/blog/4-ways-to-improve-ecommerce-ux-for-website-visitors/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="with-arrow"
                              style={{
                                fontSize: '16px',
                                color: '#0446CA',
                                fontWeight: 700,
                                textDecoration: 'none',
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              Read more on our blog
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '32px 40px 12px' }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <a
                              href="/blog/5-tips-to-create-best-ux-in-the-remote-work-culture/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ display: 'block' }}
                            >
                              <Img
                                fluid={remoteUX.childImageSharp.fluid}
                                alt="Blog post - 5 tips to create the best UX in the remote work culture"
                                style={{
                                  border: 0,
                                  lineHeight: '100%',
                                  outline: 0,
                                  display: 'block',
                                  height: 'auto',
                                  borderRadius: '6px',
                                  maxWidth: '100%',
                                }}
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a
                              href="/blog/5-tips-to-create-best-ux-in-the-remote-work-culture/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: 'none',
                                color: '#061631',
                                fontWeight: 800,
                                marginTop: '24px',
                                display: 'block',
                              }}
                            >
                              5 tips to create the best UX in the remote work culture
                            </a>
                            <p
                              style={{
                                color: '#9b9b9b',
                                fontSize: 16,
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              Living in a fast-changing world, the COVID-19 pandemic has added many
                              changes in the business world. Many companies have shifted their focus
                              to work from home (the new normal), which has its own merits and
                              demerits.
                            </p>

                            <a
                              href="/blog/5-tips-to-create-best-ux-in-the-remote-work-culture/"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="with-arrow"
                              style={{
                                fontSize: '16px',
                                color: '#0446CA',
                                fontWeight: 700,
                                textDecoration: 'none',
                                marginTop: 8,
                                display: 'block',
                              }}
                            >
                              Read more on our blog
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '0 40px' }}>
                    <p
                      style={{
                        color: '#000',
                        fontSize: 16,
                        marginTop: 24,
                        display: 'block',
                        marginBottom: 0,
                      }}
                    >
                      Want to know more? Feel free to contact us by replying to this email. Follow
                      us on Facebook, Twitter and LinkedIn to be one of the first to know what’s
                      happening!
                    </p>
                  </td>
                </tr>

                <tr>
                  <td style={{ padding: '40px 60px 15px 40px' }}>
                    <p style={{ margin: 0, fontSize: '16px', lineHeight: '24px' }}>Best,</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '15px 40px 32px' }}>
                    <table className="kamil" cellSpacing="0" cellPadding="0">
                      <tbody>
                        <tr style={{ verticalAlign: 'middle' }}>
                          <th>
                            <img
                              src="https://emails.livesession.io/pete_rounded.png"
                              className="kamil-img"
                              style={{ maxWidth: '56px' }}
                              alt="kamil"
                            />
                          </th>
                          <th style={{ paddingLeft: '16px' }}>
                            <p
                              className="info"
                              style={{
                                margin: 0,
                                textAlign: 'left',
                                fontWeight: 400,
                                lineHeight: '140%',
                              }}
                            >
                              <strong>Pete</strong> <br />
                              Product Marketing Manager @ LiveSession
                            </p>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const Newsletter = NewsletterTemplate(Content);

export const frontmatter = {
  title: 'LiveSession Newsletter February 2021',
  description: "Read LiveSession's monthly update from February 2021.",
  date: '2021-02-01',
  link: '/newsletters/february-2021/',
};

export default () => <Newsletter {...frontmatter} />;
